.jobsfeed-container {
  padding-top: 8px;
  padding-bottom: 8px;
}

.jobsfeed-container-footer {
  text-align: center;
  padding: 16px;
}

.jobsfeed-container-header {
  text-align: center;
  padding: 0px 8px 8px;
}

.jobstags {
  text-align: center;
  padding: 16px 0px 0px;
}

.jobstags-item {
  margin-left: 4px;
  margin-right: 4px;
}

.error {
  margin: 16px;
}

.description-container {
  padding-top: 72px;
  padding-bottom: 8px;
}

.jobstags-feed-separator{
  padding-top: 40px;
}
